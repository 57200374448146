import { useEffect, useRef } from "react";
import { init } from "ityped";
import "./intro.scss";

export default function Intro() {
    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
            showCursor: true,
            backDelay: 1000,
            backSpeed: 60,
            strings: ["Web Developer", "Designer"],
        });
    }, []);

    return (
        <div className="intro" id="intro">
            <div className="left">
                <div className="imgContainer">
                    <img src="assets/mee.jpg" alt="me_pic" />
                </div>
            </div>
            <div className="right">
                <div className="wrapper">
                    <h2>Jojolapa Pasapi, I'm </h2>
                    <h1>Sanat Mool</h1>
                    <h3>
                        Freelance <span ref={textRef}></span>
                    </h3>
                    <a href="#portfolio">
                        <img src="assets/down.png" alt="down" />
                    </a>
                </div>
            </div>
        </div>
    );
}

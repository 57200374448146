import "./certificates.scss";

export default function Certificates() {
    const data = [
        {
            id: "1",
            title: "AWS Certificate",
            img: "./certificates/AWS.jpg",
        },
        {
            id: "2",
            title: "NodeJS Certificate",
            img: "./certificates/NodeJS.jpg",
        },
        {
            id: "3",
            title: "Front-End Certificate",
            img: "./certificates/FrontEnd.jpg",
        },
        {
            id: "4",
            title: "AWS Certificate",
            img: "./certificates/AWS.jpg",
        },
    ];

    return (
        <div className="certificates" id="certificates">
            <h1>Certificates</h1>

            <div className="slider">
                <div className="container">
                    <figure>
                        {data.map((d) => (
                            <img
                                key={d.id}
                                src={d.img}
                                alt={d.title}
                                className="responsive"
                            />
                        ))}
                    </figure>
                </div>
            </div>
        </div>
    );
}

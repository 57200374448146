import "./testimonials.scss";

export default function Testimonials() {
    const data = [
        {
            id: 1,
            name: "Denim Krishna Shrestha",
            company: "Founder of Fitbox Pvt. Ltd.",
            desc: "We help you achieve your health goals by delivering healthy meals, made with real ingredients, love and taste for real people like you. The easiest and reasonable way to maintain diet and stay fit.",
            img: "https://scontent.fktm10-1.fna.fbcdn.net/v/t1.6435-9/79807465_10157906806829772_8144738081895874560_n.jpg?_nc_cat=102&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=hYFt6pdgtRcAX-GNVlU&_nc_ht=scontent.fktm10-1.fna&oh=7770290253f4d42a0ae35283a34b9022&oe=60F47849",
            icon: "assets/instagram.png",
            instaLink: "https://www.instagram.com/fitboxnepal/",
            website: "https://fitboxnepal.com/#/",
        },
        {
            id: 2,
            name: "PAC Asia",
            company: "360 Education Pvt. Ltd.",
            desc: "Web Application for consultancy and consultants to manage relationship with student",
            img: "assets/pacasia.jpeg",
            icon: "assets/instagram.png",
            instaLink: "https://www.instagram.com/pacasia.nepal/",
            featured: true,
        },
        {
            id: 3,
            name: "Print Sewa",
            company: "Print Sewa Pvt. Ltd.",
            desc: "Nepal's First Online Printing Platform",
            img: "assets/printsewa.png",
            icon: "assets/instagram.png",
            instaLink: "https://www.instagram.com/printsewa/",
            website: "https://printsewa.com.np/#/",
        },
    ];

    return (
        <div className="testimonials" id="testimonials">
            <h1>Testimonials</h1>
            <div className="container">
                {data.map((d) => (
                    <div
                        key={d.id}
                        className={d.featured ? "card featured" : "card"}
                    >
                        <div className="top">
                            <img
                                src="assets/right-arrow.png"
                                className="left"
                                alt="arrow"
                            />
                            <a
                                href={d.website}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="user"
                                    src={d.img}
                                    alt="testimonials"
                                />
                            </a>
                            <a
                                href={d.instaLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="right"
                                    src={d.icon}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="center">{d.desc}</div>
                        <div className="bottom">
                            <h3>{d.name}</h3>
                            <a
                                href={d.website}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <h4>{d.company}</h4>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

import { useState, useEffect } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";

import { frontend, backend, other, design, cloudServices } from "../../data";

export default function Portfolio() {
    const [selected, setSelected] = useState("backend");
    const [data, setData] = useState([]);
    const list = [
        {
            id: "backend",
            title: "Back-End",
        },
        {
            id: "frontend",
            title: "Front-End",
        },
        {
            id: "design",
            title: "Design",
        },
        {
            id: "cloudServices",
            title: "Cloud Services",
        },
        {
            id: "other",
            title: "Other",
        },
    ];

    useEffect(() => {
        switch (selected) {
            case "frontend":
                setData(frontend);
                break;
            case "backend":
                setData(backend);
                break;
            case "design":
                setData(design);
                break;
            case "cloudServices":
                setData(cloudServices);
                break;
            case "other":
                setData(other);
                break;
            default:
                setData(backend);
        }
    }, [selected]);

    return (
        <div className="portfolio" id="portfolio">
            <h1>Skills</h1>
            <ul>
                {list.map((item) => (
                    <PortfolioList
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        active={selected === item.id}
                        setSelected={setSelected}
                    />
                ))}
            </ul>
            <div className="container">
                {data.map((d) => (
                    <div key={d.id} className="item">
                        <img src={d.img} alt="mobile app" />
                        <h3>{d.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

export const backend = [
    {
        id: 1,
        title: "Node.js",
        img: "assets/nodejs.png",
    },
    {
        id: 2,
        title: "Express.js",
        img: "assets/express.png",
    },
    {
        id: 3,
        title: "Next.js",
        img: "assets/nextjs.png",
    },
    {
        id: 4,
        title: "MySQL",
        img: "assets/mysql.png",
    },
    {
        id: 5,
        title: "Sequelize",
        img: "assets/sequelize.png",
    },
    {
        id: 6,
        title: "Mongo",
        img: "assets/mongo.png",
    },
    {
        id: 7,
        title: "REST API",
        img: "assets/restAPI.png",
    },
    {
        id: 8,
        title: "GraphQL",
        img: "assets/graphql.png",
    },
];

export const frontend = [
    {
        id: 1,
        title: "Angular",
        img: "/assets/angular.png",
    },
    {
        id: 2,
        title: "React",
        img: "/assets/react.png",
    },
    {
        id: 3,
        title: "Redux",
        img: "/assets/redux.png",
    },
    {
        id: 4,
        title: "HTML",
        img: "/assets/html.png",
    },
    {
        id: 5,
        title: "CSS",
        img: "/assets/css.png",
    },
    {
        id: 6,
        title: "Bootstrap",
        img: "/assets/bootstrap.png",
    },
];

export const design = [
    {
        id: 1,
        title: "XD",
        img: "assets/xd.png",
    },
    {
        id: 2,
        title: "Figma",
        img: "assets/figma.png",
    },
];

export const cloudServices = [
    {
        id: 1,
        title: "AWS",
        img: "/assets/aws.png",
    },
    {
        id: 2,
        title: "Digital Ocean",
        img: "/assets/digitalocean.png",
    },
];

export const other = [
    {
        id: 1,
        title: "Github",
        img: "assets/github.png",
    },
    {
        id: 2,
        title: "Github",
        img: "assets/gitlab.png",
    },
];

import { useState } from "react";
import "./App.scss";

import TopBar from "./components/topbar/TopBar";
import Intro from "./components/intro/Intro";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Menu from "./components/Menu/Menu";
import Certificates from "./components/certificates/Certificates";
// import Contact from "./components/contact/Contact";

function App() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="app">
            <TopBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div className="sections">
                <Intro />
                <Portfolio />
                <Certificates />
                <Testimonials />
                {/* <Contact /> */}
            </div>
        </div>
    );
}

export default App;
